/** @jsx jsx */
import {jsx} from '@emotion/core'
import styled from '@emotion/styled/macro';
import * as colors from '../styles/colors';
import * as mq from '../styles/media-queries';
import {Link} from 'react-router-dom';

export const Logo = ({width = '48', height = '48'}) => {
  return (<svg id="carloshm-logo" xmlns="http://www.w3.org/2000/svg" width={`${width}px`} height={`${height}px`} viewBox="0 0 24.094 33.636">
  <path id="Body" d="M55.781,34.405a12.047,12.047,0,0,0-21.721,7.226V59.156a4.012,4.012,0,0,0,5.8,3.589l3.26-1.629a6.673,6.673,0,0,1,5.964,0l3.26,1.629a4.012,4.012,0,0,0,5.8-3.589V41.663A11.994,11.994,0,0,0,55.781,34.405Z" transform="translate(-34.06 -29.537)" fill="#d9d9dd"/>
  <circle id="Head" cx="11.097" cy="11.097" r="11.097" transform="translate(0.95 0.95)" fill="#fff"/>
  <circle id="Eye" cx="1.824" cy="1.824" r="1.824" transform="translate(15.298 6.511)" fill="#3b3b3b"/>
</svg>)
};

export const LogoName = ({width = '150', height = '32'}) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={`${width}px`} height={`${height}px`} viewBox="0 0 161.58 33.636">
      <g id="carloshm-logo-name" transform="translate(-34.061 -29.537)">
        <g id="carloshm-name" transform="translate(71.542 43.082)">
          <path id="Path_2507" data-name="Path 2507" d="M443.8,146.226a6.006,6.006,0,0,0,6.1,6.121,6.242,6.242,0,0,0,5.05-2.474l2.372,2.117a9.383,9.383,0,0,1-7.5,3.417,9.185,9.185,0,1,1-.051-18.363,9.279,9.279,0,0,1,6.529,2.55l-2.219,2.346a6.117,6.117,0,0,0-4.336-1.836A5.957,5.957,0,0,0,443.8,146.226Z" transform="translate(-440.352 -137.045)" fill="#212121"/>
          <path id="Path_2508" data-name="Path 2508" d="M665.075,212.93h-3.188v-.995a5.172,5.172,0,0,1-3.443,1.25,6.219,6.219,0,0,1-4.667-2.091,6.639,6.639,0,0,1-1.581-4.336,6.209,6.209,0,0,1,6.07-6.452,5.667,5.667,0,0,1,3.621,1.25v-.995h3.188ZM661.76,204.9a3.8,3.8,0,0,0-3.061-1.53,3.383,3.383,0,0,0,.077,6.758,3.622,3.622,0,0,0,2.984-1.53Z" transform="translate(-633.834 -194.823)" fill="#212121"/>
          <path id="Path_2509" data-name="Path 2509" d="M852.437,203.494a4.609,4.609,0,0,0-1.2-.128,3.67,3.67,0,0,0-3.009,1.785v7.779h-3.315V200.561H848.1v1.25a4.63,4.63,0,0,1,3.6-1.5,6.753,6.753,0,0,1,1.122.1Z" transform="translate(-809.848 -194.823)" fill="#212121"/>
          <path id="Path_2510" data-name="Path 2510" d="M968.859,155.153h-3.315V137.045h3.315Z" transform="translate(-920.021 -137.045)" fill="#212121"/>
          <path id="Path_2511" data-name="Path 2511" d="M1042.738,200.306a6.441,6.441,0,1,1-6.58,6.452A6.441,6.441,0,0,1,1042.738,200.306Zm0,9.819a3.381,3.381,0,1,0-3.264-3.366A3.231,3.231,0,0,0,1042.738,210.125Z" transform="translate(-984.516 -194.823)" fill="#212121"/>
          <path id="Path_2512" data-name="Path 2512" d="M1207.829,209.156a5.209,5.209,0,0,0,3.392,1.224c.892,0,1.581-.434,1.581-1.046,0-1.862-5.993-1.2-5.993-5.177,0-2.372,2.3-3.851,4.744-3.851a7.673,7.673,0,0,1,4.183,1.25l-1.556,2.4a4.172,4.172,0,0,0-2.372-.842c-.918,0-1.683.357-1.683.995,0,1.607,5.994.969,5.994,5.254,0,2.372-2.321,3.826-4.922,3.826a8.556,8.556,0,0,1-4.973-1.658Z" transform="translate(-1139.838 -194.823)" fill="#212121"/>
          <path id="Path_2513" data-name="Path 2513" d="M1448.967,149.806h-8.926v8.034H1436.6V139.987h3.443v6.759h8.926v-6.759h3.443V157.84h-3.443Z" transform="translate(-1350.246 -139.732)" fill="#212121"/>
          <path id="Path_2514" data-name="Path 2514" d="M1675.023,150.673l6.5-10.686h2.423V157.84h-3.443v-9.946l-4.285,7.014h-2.4l-4.285-7.014v9.946H1666.1V139.987h2.423Z" transform="translate(-1559.852 -139.732)" fill="#212121"/>
        </g>
        <g id="carloshm-logo" transform="translate(34.06 29.537)">
          <path id="Body" d="M55.781,34.405a12.047,12.047,0,0,0-21.721,7.226V59.156a4.012,4.012,0,0,0,5.8,3.589l3.26-1.629a6.673,6.673,0,0,1,5.964,0l3.26,1.629a4.012,4.012,0,0,0,5.8-3.589V41.663A11.994,11.994,0,0,0,55.781,34.405Z" transform="translate(-34.06 -29.537)" fill="#d9d9dd"/>
          <circle id="Head" cx="11.097" cy="11.097" r="11.097" transform="translate(0.95 0.95)" fill="#fff"/>
          <circle id="Eye" cx="1.824" cy="1.824" r="1.824" transform="translate(15.298 6.511)" fill="#3b3b3b"/>
        </g>
      </g>
    </svg>
  )
};

const buttonVariants = {
  primary: {
    color: colors.white,
    background: colors.blueJeans,
    boxShadow: '0px 25px 30px 0px rgba(88, 184, 252, 0.16)',
    [mq.hover]: {
      ':hover': {
        background: colors.carolinaBlue,
      }
    },
  },
  secondary: {
    color: colors.blueJeans,
    border: `solid 2px ${colors.blueJeans}`,
    '& img': {
      filter: 'invert(64%) sepia(27%) saturate(1894%) hue-rotate(180deg) brightness(104%) contrast(97%)',
    },
    [mq.hover]: {
      ':hover': {
        color: colors.carolinaBlue,
        border: `solid 2px ${colors.carolinaBlue}`,
        '& img': {
          transition: 'all ease 0.2s',
          filter: 'invert(44%) sepia(81%) saturate(1204%) hue-rotate(181deg) brightness(100%) contrast(98%)',
        },
      }
    },
  },
};

export const Button = styled.button({
  width: '90%',
  boxSizing: 'border-box',
  maxHeight: '57px',
  padding: '18px 36px',
  display: 'flex',
  justifyContent: 'center',
  fontSize: '18px',
  fontWeight: 'bold',
  textDecoration: 'none',
  borderRadius: '18px',
  border: 0,
  background: colors.white,
  [mq.hover]: {
    transition: 'all ease 0.2s',
    cursor: 'pointer',
  },  
  [mq.medium]: {
    width: 'auto',
  }
}, ({variant = 'primary'}) => buttonVariants[variant]);

export const Label = styled.label({
  marginBottom: '5px',
  fontSize: '14px',
  fontWeight: 'bold',
  color: colors.gray87,
});

const inputVarinats = {
  warning: {
    outline: `${colors.tartOrange} auto 1px`,
    '&:focus-visible': {
      outline: `${colors.tartOrange} auto 1px`,
    },
  }
}

export const Input = styled.input({
  boxSizing: 'border-box',
  width: '100%',
  padding: '10px',
  background: colors.cultured,
  border: `solid 1px ${colors.gainsboro}`,
  borderRadius: '6px',
  fontSize: '16px',
  '&:focus-visible': {
    outline: `${colors.blueJeans} auto 1px`,
  },
  '&::placeholder': {
    color: colors.gray30,
    fontWeight: 'medium',
  },
}, ({variant = ''}) => inputVarinats[variant]);

export const H2 = styled.h2({
  fontSize: '28px',
  marginBottom: '20px',
  [mq.medium]: {
    fontSize: '32px',
  },
  [mq.Xlarge]: {
    fontSize: '44px',
  },
});

export const H2s = styled.h2({
  fontSize: '18px',
  fontWeight: 'medium',
  color: colors.gray54,
});

export const H3 = styled.h3(({marginBottom = '20px'}) => {
  return {
    fontSize: '22px',
    fontWeight: 'bold',
    color: colors.gray87,
    marginBottom,
    [mq.medium]: {
      fontSize: '28px',
    },
    [mq.large]: {
      fontSize: '35px',
    }
  }
});

export const H4 = styled.h4({
  fontSize: '20px',
  fontWeight: 'bold',
  color: colors.gray87,
  marginBottom: '10px',
  [mq.medium]: {
    fontSize: '24px',
  }
});

export const H5 = styled.h5({
  fontSize: '18px',
  fontWeight: 'bold',
  color: colors.gray87,
  marginBottom: '10px',
});

export const Container = styled.div({
    padding: '0 30px',
    boxSizing: 'border-box',
});

export const Content = styled.div({
  width: '100%',
  margin: '0 auto',
  [mq.medium]: {
    width: '95%',
  },
  [mq.large]: {
    width: '90%',
    maxWidth: '62.5rem',
  }
});


export const Textarea = styled.textarea({
  boxSizing: 'border-box',
  width: '100%',
  padding: '10px',
  background: colors.cultured,
  border: `solid 1px ${colors.gainsboro}`,
  borderRadius: '6px',
  fontSize: '16px',
  '&:focus-visible': {
    outline: `${colors.blueJeans} auto 1px`,
  },
  '&::placeholder': {
    color: colors.gray30,
    fontWeight: 'medium',
  },
});

export const ButtonNav = styled.button({
  fontWeight: 'Semibold',
  fontSize: '16px',
  color: colors.gray30,
  background: 'none',
  border: 0,
})

const liNavVariants = {
  active: {
    fontSize: '30px',
    fontWeight: 'bold',
    color: colors.gray87,
  },
  highlight: {
    color: colors.blueJeans,
    fontWeight: 'bold',
    [mq.hover]: {
      ':hover': {
        color: colors.carolinaBlue,
      },
    }
  },
}

export const NavLiButton = styled.button({
  fontSize: '24px',
  fontWeight: 'Semibold',
  color: colors.gray30,
  marginBottom: '50px',
  background: 'none',
  border: 'none',
  [mq.hover]: {
    cursor: 'pointer',
    transition: 'color ease 0.4s',
    ':hover': {
      color: colors.gray87,
    },
  },  
  [mq.large]: {
    fontSize: '16px',
    marginBottom: '5px',
  },
}, ({variant = ''}) => liNavVariants[variant])




export const LiNav = styled.li({
    fontSize: '24px',
    fontWeight: 'Semibold',
    color: colors.gray30,
    marginBottom: '50px',
    [mq.hover]: {
      transition: 'color ease 0.4s',
      ':hover': {
        color: colors.gray87,
      },
    },  
    [mq.large]: {
      fontSize: '16px',
      marginBottom: '5px',
    },
}, ({variant = ''}) => liNavVariants[variant]);

export const TitleParagraphs = ({title, paragraphs}) => {
  return (
    <div css={{
      maxWidth: '820px', 
      margin: '0 auto',
      [mq.large]: {
        width: '80%',
        '& h4': {
          fontWeight: '900',
        },
        '& p': {
          fontSize:'18px',
        }
      }
      }}>
      <H4>{title}</H4>
      {paragraphs.map((p, i) => <p key={i}>{p}</p>)}
    </div>
  )
};

export const Footer = () => {
  return (
    <footer 
      className='container'
      css={{
        width: '100%',
        margin: '80px 0 40px',
        display: 'flex',
        alignItems: 'flex-start',
        justifyContent: 'space-between',
        'p': {
          margin: 0,
          fontWeight: '600',
        }
      }}
    >
      <p>Carlos HM®</p>
      <div css={{
        width: 'fit-content',
        display: 'flex',
        '& a': {
          padding: '4px'
        }
      }}>
        <a href="https://www.linkedin.com/in/carlos-hm" target="_blank" rel="noreferrer">
          <figure>
            <img src='/icons/ic-linkedin.svg' alt='LinkedIn.'/>
          </figure>
        </a>
        <a href="https://github.com/carlos-hm" target="_blank" rel="noreferrer" >
          <figure>
            <img src='/icons/ic-github.svg' alt='Github.'/>
          </figure>
        </a>
      </div>
    </footer>
  )
};

const liToggleVariants = {
  active: {
    fontSize: '24px',
    color: colors.carolinaBlue,
  },
  inactive: {
    fontSize: '18px',
    color: colors.gray20,
    [mq.hover]: {
      cursor: 'pointer',
      ':hover': {
        color: colors.gray87,
      }
    }
  }
};

export const LiToggle = styled(Link)({
  display: 'flex',
  textDecoration: 'none',
  alignItems: 'flex-end',
  height: '40px',
  fontWeight: 'bold',
  fontFamily: '"Satoshi", sans-serif',
  marginRight: '20px',
  background: 'none',
  border: 'none',
  transition: 'all ease 0.4s',
}, ({variant = 'inactive'}) => liToggleVariants[variant]);


const figureVariants = {
  '1:1': {
    aspectRatio: '1 / 1',
    '> img': {
      width: '50%',
    },
    [mq.medium]: {
      width: '40%',
    }
  },
  '3:2': {
    aspectRatio: '3 / 2',
    '> img': {
      height: '100%',
      width: 'auto',
    },
    [mq.medium]: {
      width: '58%',
    },
  },
  '5:4': {
    aspectRatio: '5 / 4',
    [mq.medium]: {
      width: '49%',
    }
  },
  'icon': {
    aspectRatio: '7 / 11',
    '> img': {
      maxWidth: '140px',
      minWidth: '100px',
      width: '16%',
    },
    [mq.medium]: {
      aspectRatio: '2 / 1',
    },
  },
  '3': {
    aspectRatio: '7 / 9',
    [mq.medium]: {
      aspectRatio: '2 / 1',
    }
  },
  '4': {
    aspectRatio: '7 / 9',
    [mq.medium]: {
      aspectRatio: '5 / 2'
    }
  }

};

export const Figure = styled.figure({
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '20px',
    backgroundColor: colors.cultured,
    overflow: 'hidden',
  }, 
  ({variant = ''}) => figureVariants[variant], 
  ({aspectRatio = '', aspectRatioMedium = '', backgroundColor = '', fWidth: width = ''}) => ({ aspectRatio, backgroundColor, width, [mq.medium]: {
    aspectRatio: aspectRatioMedium,
  }}),
);

export const Img = styled.img(({width = '100%', widthMedium = '', transform, transformMedium, shadowColor, blur = "0px 15px 30px"}) => {
  return {
    width,
    transform,
    filter: shadowColor ? `drop-shadow(${blur} ${shadowColor})` : 'none',
    [mq.medium]: {
      width: widthMedium,
      transform: transformMedium,
    }
  }
});

const Bg1 = ({width = '48px', blur = '50px', opacity = '.3', fill = '#2cc1f8',  top, left, widthM, topM, leftM, widthL, topL, leftL}) => {
  return (
    <svg 
      css={{
        width,
        filter: `blur(${blur})`, 
        opacity, 
        position: 'absolute', 
        top, 
        left,
        [mq.medium]: {
          width: widthM,
          top: topM,
          left: leftM,
        },
        [mq.large]: {
          width: widthL,
          left: leftL,
          top: topL,
        }
      }} 
      xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" width="522.002" height="673" viewBox="0 0 522.002 673">
      <path id="Path_998" data-name="Path 998" d="M20017-3128c0-52.626,40.924-58.872,46.9-95.551S20049-3276.2,20049-3311a79,79,0,0,1,79-79,79,79,0,0,1,79,79c0,34.806-21.219,53.038-19.779,74.882S20239-3180.625,20239-3128a111,111,0,0,1-111,111A111,111,0,0,1,20017-3128Z" transform="translate(-19867 3540)" fill={fill}/>
    </svg>
  )
};

const Bg2 = ({width = '48', height = '48', blur = '50px', opacity = '.3', fill = '#2cc1f8',top, left, widthM, topM, leftM, widthL, topL, leftL}) => {
  return (
    <svg 
      css={{
        width,
        filter: `blur(${blur})`, 
        opacity, 
        position: 'absolute', 
        top, 
        left,
        [mq.medium]: {
          width: widthM,
          top: topM,
          left: leftM,
        },
        [mq.large]: {
          width: widthL,
          top: topL,
          left: leftL,
        }
      }} 
      xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" width="673" height="523.389" viewBox="0 0 673 523.389">
      <path id="Path_999" data-name="Path 999" d="M20017-3128c0-52.626-8.979-107.674,18.771-173.175S20084.367-3390,20128-3390a79,79,0,0,1,79,79c0,34.806-21.219,53.038-19.779,74.882S20239-3180.625,20239-3128a111,111,0,0,1-111,111A111,111,0,0,1,20017-3128Z" transform="translate(3540 20389.002) rotate(-90)" fill={fill}/>
    </svg>
  )
};


export const BgBlured = ({one, two, className}) => {
  return (
    <div 
      className={className}
      css={{
        position: 'absolute',
        top: '0',
        left: '0',
        width: '100%',
        height: '100%',
        zIndex: '-1',
        overflow: 'hidden',
      }}
    >
      <Bg1 {...one}></Bg1>
      <Bg2 {...two}></Bg2>
    </div>
  )
}


export const ButtonClose = ({onClick}) => {
  return (
  <button 
    css={{
      background: 'none',
      border: 'none',
      [mq.hover]: {
        cursor: 'pointer'
      },
    }}
    onClick={onClick}
  >
    <svg id="ic-close-circle" xmlns="http://www.w3.org/2000/svg" width="44" height="44" viewBox="0 0 44 44">
      <rect id="Frame" width="44" height="44" fill="#fff" opacity="0"/>
      <circle id="Oval" cx="15" cy="15" r="15" transform="translate(7 7)" fill="rgba(118,118,128,0.12)"/>
      <path id="Symbol" d="M2.707-.652a1.13,1.13,0,0,0,.539.3,1.277,1.277,0,0,0,.619,0A1.12,1.12,0,0,0,4.393-.64L8.738-4.994,13.086-.646a1.111,1.111,0,0,0,.53.3,1.247,1.247,0,0,0,.612,0,1.14,1.14,0,0,0,.536-.306,1.1,1.1,0,0,0,.309-.539,1.319,1.319,0,0,0,0-.616,1.051,1.051,0,0,0-.3-.528L10.433-6.689l4.339-4.339a1.067,1.067,0,0,0,.3-.531,1.319,1.319,0,0,0,0-.616,1.079,1.079,0,0,0-.309-.536,1.151,1.151,0,0,0-.536-.307,1.224,1.224,0,0,0-.612,0,1.134,1.134,0,0,0-.53.3L8.738-8.378,4.393-12.726a1.131,1.131,0,0,0-.529-.294,1.265,1.265,0,0,0-.619,0,1.13,1.13,0,0,0-.539.3,1.105,1.105,0,0,0-.306.542,1.279,1.279,0,0,0,0,.619,1.131,1.131,0,0,0,.294.525L7.052-6.689,2.7-2.335a1.112,1.112,0,0,0-.294.524,1.279,1.279,0,0,0,0,.619A1.1,1.1,0,0,0,2.707-.652Z" transform="translate(13.636 29)" fill="rgba(60,60,67,0.6)"/>
    </svg>
  </button>
)}