/** @jsx jsx */
import {jsx} from '@emotion/core'
import * as colors from '../styles/colors';
import * as mq from '../styles/media-queries';
import { H2s, H3, H4, Figure, Img, BgBlured, Button  } from './lib';
import { Link } from 'react-router-dom';

export const ProjectCard = ({title, slug, iconURL, caption, subtitle, roles, shadowColor, maxWidth = '140px', width = '50%'}) => {
  return (
    <Link
      to={`/project/${slug}`} 
      css={{
        maxWidth: '350px',
        height: '470px',
        padding: '80px 20px 40px',
        borderRadius: '20px',
        boxSizing: 'border-box',
        background: colors.cultured,
        textDecoration: 'none',
        [mq.hover]: {
          aspectRatio: '1 / 1',
          maxWidth: '300px',
          height: 'auto',
          padding: '0',
          flexWrap: 'wrap',
          flexDirection: 'row',
          overflowY: 'hidden',
        },
        [mq.medium]: {
          width: '46%',
          maxWidth: '425px',
          borderRadius: '30px',
        },
        [mq.large]: {
            width: '48%',
            maxWidth: '350px',
          [mq.hover]: {
            width: '30%',
            maxWidth: '300px',
          },
          borderRadius: '40px',
        }
    }}>
      <div css={{
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'space-between',
        [mq.hover]: {
          height: 'auto',
          transition: 'transform ease-out 0.3s',
          ':hover': {
            transform: 'translateY(-50%)',
          }
        }
      }}>
        <div css={{
          [mq.hover]: {
            width: '100%', 
            height: '100%', 
            display: 'flex', 
            justifyContent: 'center', 
            alignItems: 'center', 
            aspectRatio: '1',
          }
        }}>

          <figure css={{ 
            maxWidth, 
            maxHeight: '140px', 
            width: '75%', 
            margin: '0 auto',
            [mq.hover]: {
              width
            }
          }}>
            <img 
              css={{ filter: `drop-shadow(0px 15px 60px ${shadowColor})`}}
              src={iconURL} alt={title}
            />
          </figure>
        </div>
        <div css={{
          height: '100%',
          marginTop: '50px',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          [mq.hover]:{boxSizing: 'border-box', width: '100%', height: '100%', margin: '0', padding: '0 20px', display: 'flex', flexDirection: 'column', justifyContent: 'flex-end', alignItems: 'center', aspectRatio: '1'}
        }}>
          <div>
            <h4 css={{
              fontSize: '22px',
              fontWeight: 'semibold',
              marginBottom: '20px',
            }}>{title}</h4>
            <p>{caption ?? subtitle}</p>
          </div>
          <p 
            css={{
              fontSize: '14px', 
              color: colors.gray54,
              [mq.hover]: {
                margin: '35% 0 12%',
                [mq.large]: {
                  margin: '20% 0 12%',
                }
              }
            }}
          >
          {roles.reduce((accumulator, current, i, arr) => `${accumulator} ${current}${i === arr.length - 1 ? '' : ' ·'}`, '')}</p>
        </div>
      </div>
    </Link>
  )
};

export const Projects = ({projects}) => {
  return (
    <div>
      <div css={{
        width:'92%',
        margin: '0 auto',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        '> a': {
          marginBottom: '8%',
          [mq.large] :{
            marginBottom: '5%',

          }
        },
        [mq.medium]: {
          display: 'flex',
          flexDirection: 'row',
          flexWrap: 'wrap',
          justifyContent: 'space-between',
        }
      }}>
        {projects.map((project, i) => <ProjectCard  key={i} {...project}/>)}
      </div>
    </div>
  )
};

export const ProjectDescription = ({title, text}) => {
  return(
    <div css={{marginBottom: '70px'}}>
      <H4 css={{textAlign: 'left'}}>{title}</H4>
      <div>
        {text.map((p, index) => <p css={{color: colors.gray54, textAlign: 'left', marginBottom: '25px'}} key={`${title}-${index}`}>{p}</p>)}
      </div>
    </div>
  )
};

export const ProjectIntro = ({project}) => {
  return (
    <section
      css={{
        [mq.medium]: {
          width: '100%',
          marginTop: '30vh',
          display: 'flex',
          justifyContent: 'space-between',
        },
        [mq.large]: {
          'p':{
            fontSize: '20px',
          },
        },
      }}
    >
      <H3 css={{
        textAlign: 'left',
        [mq.medium]: {
          width: '20%',
        },  
        }} 
        marginBottom='40px'
      >
        Project
      </H3>
      <div
        css={{
          [mq.medium]: {
            width: '72%',
            maxWidth: '715px',
          },  
        }}  
      >
        {project.descriptions.map((desc, i) => 
          <ProjectDescription key={`description-${i}`} title={desc.title} text={desc.text}/>
        )}
      </div>
    </section>
  );
}

const ProjectAppIcon = ({width = '75px', iconURL, title, shadowColor}) => {
  return (
    <figure 
      className='app-icon'
      css={{ 
        width, 
      }}
    >
      <Img src={iconURL} alt={`${title} icon`} shadowColor={shadowColor}/>
    </figure>
  )
};

export const ProjectHero = ({project}) => {
  const {title, subtitle, iconURL, hero:{blur:[blurOne, blurTwo], type = 'app', url, medium = '38%', large = '30%', padding, pMedium, pLarge} } = project;
  const heroTypes = {
    horizontal: {
      flexDirection: 'column-reverse',
      paddingBottom:'30vh',
      '& .app-icon': {
        display: 'none',
      },
      '& .hero-photo':{
        width:'100%',
        margin: '0 auto 75px'
      },
    }
  };

  return (
    <div css={{
      paddingTop: '130px', 
      display:'flex', 
      flexDirection: 'column', 
      position: 'relative',
      ...heroTypes[type],
      '& .bg-hero':{
        left: '-30px',
        width: '100vw',
      },
      [mq.medium]:{
        flexDirection: 'row',
        paddingTop: '14vh',
        paddingBottom: '0',
        justifyContent: 'space-between',
        alignItems: 'center',
        '.app-icon': {
          display: 'none',
        },
        '& .bg-hero':{
          left: '-10%',
          width: '120%',
        }
      },
      [mq.large]:{
        paddingTop: '150px',
      }
    }}>
      <BgBlured 
        className='bg-hero'
        one={blurOne}
        two={blurTwo}
      />
      <div css= {{ 
        display: 'flex', 
        flexDirection: 'column', 
        alignItems: 'center', 
        [mq.medium]: {
          maxWidth: '425px',
          width: '56%',
          marginBottom: '60px',
          alignItems: 'flex-start',
          textAlign: 'left',
        },
        [mq.large]: {
          maxWidth: '580px',
        }
      }}>
        <ProjectAppIcon iconURL={iconURL} title={title}/>
        <H2s css={{marginTop: '15px'}}>{title}</H2s>
        <H3 css={{
          marginTop: '45px', 
          [mq.medium]: {
            marginTop: '10px',
          }
        }}>{subtitle}</H3>
      </div>
      <div css={{
        padding,
        [mq.medium]: {
          width: medium,
          padding: pMedium,
          minWidth: '250px',
        },
        [mq.large]: {
          width: large,
          padding: pLarge,
        },
      }}>
        <figure 
          className='hero-photo'
          css={{
            width: '73%', 
            margin: '130px auto 150px',
            [mq.medium]: {
              width: '100%',
              margin: '0',
            }
          }}>
          <Img src={url} alt={`${title}`} shadowColor='rgba(0,0,0,0.16)' />
        </figure>
      </div>
    </div>
  )
};

const ProjectMedia = ({
  title,
  type = 'image', 
  mediaURL,
  figureOptions: {
    variant,
    fWidth, 
    aspectRatio = '', 
    aspectRatioMedium, 
    backgroundColor = colors.cultured
  } = {},
  videoOptions: {
    autoPlay = false, 
    loop = false, 
    muted = true, 
    height = '100%'
  } = {},  
  imageOptions: { 
    width,  
    widthMedium, 
    transform, 
    transformMedium, 
  } = {},
  options: {
    shadowColor,
  } = {},
}) => {
  return (
    <Figure variant={variant} width={width} fWidth={fWidth} aspectRatio={aspectRatio} aspectRatioMedium={aspectRatioMedium} backgroundColor={backgroundColor}>
      {
        type === 'image' 
        ? <Img width={width} widthMedium={widthMedium} transform={transform} transformMedium={transformMedium} shadowColor={shadowColor} src={mediaURL} alt={title}/> 
        : <video playsInline={true} autoPlay={autoPlay} loop={loop} muted={muted}
          css={{
            height,
            borderRadius: '20px',
            filter: shadowColor ? `drop-shadow(0px 15px 30px ${shadowColor})` : 'none',
          }} 
          >
            <source src={mediaURL} type='video/mp4' />
          </video>
      }
    </Figure>
  )
}

export const ProjectSection = ({title, text, images = [], videos = [], media = []}) => {

  return (
    <section>
      <div css={{
        margin: '100px 0 30px', 
        textAlign: 'left', 
        '> p':{
          maxWidth: '750px',
        },
        [mq.medium]: {
          margin: '150px 0 30px',
        },
        [mq.large]: {
          margin: '200px 0 30px',
          'p': {
            fontSize: '20px',
          }
        }
      }}>
        <H3>{title}</H3>
        {text.map((p, index) => 
          <p key={`project-section-${index}`} css={{color: colors.gray54, marginBottom: '25px'}}>{p}</p>)
        }
      </div>
      <div css={{
        '> figure': {
          marginBottom: '20px'
        },
        [mq.medium]: {
          display: 'flex',
          flexWrap: 'wrap',
          justifyContent: 'space-between',
        }
      }}>
        { media.map((props, index) => <ProjectMedia {...props} key={`media-${index}`}/>)}
      </div>
    </section>
  )
}

export const ProjectLinks = ({title, roles, iconURL, links, shadowColor}) => {
  return (
    <div css={{
      border: `solid 0.5px ${colors.gainsboro}`, 
      borderRadius: '20px', 
      margin: '100px 0',
      padding: '100px 0',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      [mq.medium]:{
        marginTop: '200px',
      }
    }}>
      <ProjectAppIcon iconURL={iconURL} title={`${title}`} width='100px' shadowColor={shadowColor}/>
      <h2 css={{
        fontSize: '28px',
        fontWeight: 'bold',
        margin: '20px 0 15px',
        [mq.Xlarge]: {
          fontSize: '30px',
        }
      }}>{title}</h2>
      <p css={{marginBottom: '35px', color: colors.gray54, fontSize:'16px' }}>{roles.reduce((accumulator, current, index, array) => index === 0 ? `${accumulator} ${current}` : `${accumulator} · ${current}`, '')}</p>
      <div css={{
        width: '70%', 
        flexWrap: 'wrap', 
        display: 'flex', 
        justifyContent: 'space-around',
        '& > a': {
          margin: '10px 0'
        }
      }}>
        {links.map(({linkURL, variant = 'primary', target, text}, i) => <Button key={`button-link-${i}`} as="a" href={linkURL} target={target} variant={variant}>{text}</Button>)}
      </div>
    </div>
  );
};

export const ProjectsMore = ({projects = []}) => {
  return (
    <div
      css={{
        marginTop: '100px',
        '> h3':{
          [mq.medium]: {
            marginTop: '200px', 
            marginBottom: '50px',
          }
        }
      }}
    >
      <H3 css={{textAlign: 'left', marginLeft: '7%'}}>More projects</H3>
      <div
        css={{
            width: '100%',
            overflowX: 'scroll',
        }}
      >
        <div 
          css={{
            minWidth: '100%',
            display: 'flex',
            flexDirection: 'row',
            paddingBottom: '30px',
            boxSizing: 'border-box',
            '::before':{
              content: '""',
              minWidth: '5%',
            },
            '::after':{
              content: '""',
              minWidth: '5%',
            },
            '> a': {
              minWidth: 'min(80%, 300px)',
              marginRight: '3.6%',
              ':last-of-type':{
                marginRight: '0',
              },
              [mq.hover]: {
                width: 'min(25%, 350px)'
              }
            },
            '> div:first-of-type': {
              marginLeft: '7%'
            },
            '> div:last-of-type': {
              marginRight: '7%'
            },
            
        }}>
          {projects.map((project, index) => <ProjectCard key={`project-card-${index}`} {...project}/> )}
        </div>
      </div>
    </div>
  )
};