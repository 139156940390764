export const text = "#46474D";
export const title = "#211E1E";
export const white = "#FFFFFF";
export const gainsboro = "#E3E4E6";
export const blueJeans = "#58B8FC";
export const carolinaBlue = "#199EFC";
export const tartOrange = '#FF312E'
export const cultured = "#F7F8FA";
export const gray20 = "#BCBECC";
export const gray30 = "#A2A4B3";
export const gray54 = "#6B6C75";
export const gray70 = "#46474D";
export const gray87 = "#211E1E";