/** @jsx jsx */
import {jsx} from '@emotion/core';
import React from 'react';
import * as colors from '../styles/colors';
import * as mq from '../styles/media-queries';
import { H3, H4, H5} from './lib';

const experiences = {
  profesional: [
    {
      title: 'Bocha',
      role: 'Creator',
      yearStart: 2021,
      yearEnd: undefined,
      description: 'Created “Bocha” mobile and web app in both ends, design and development.',
      activities: [
        'Front-end development.',
        'Back-end development.',
        'UX & UI design.',
        'Design system, visual identity and logo. ',
        'Business model.',
      ],
    },
    {
      title: 'SafeRoom',
      role: 'Head of Design',
      yearStart: 2020,
      yearEnd: undefined,
      description: 'Led the design for the new “SafeRoom” mobile app, admin tool, website and communication channels. (iOS, Android and Web app).',
      activities: [
        'UX & UI design.',
        'Design system, visual identity & logo.',
        'UI animations.',
        'Front-end development.',
        'Motion graphics, mockups & sales materials.',
      ],
    },
    {
      title: 'Punto Casa de Bolsa \n Grupo Salinas',
      role: 'Head Designer UX & UI',
      yearStart: 2016,
      yearEnd: 2020,
      description: 'Led the design for the new “SafeRoom” mobile app, admin tool, website and communication channels. (iOS, Android and Web app).',
      activities: [
        'UX & UI design.',
        'Design system, visual identity & logo.',
        'UI animations.',
        'Front-end development.',
        'Motion graphics, mockups & sales materials.',
      ],
    },
    
  ],
  education: [
    {
      title: 'Ironhack',
      description: 'Full-stack web developer \n Intensive 9-week course',
      location: 'Mexico City, Mexico',
      yearStart: 2019,
      yearEnd: 2019,
    }, 
    {
      title: 'Universidad Iberoamericana',
      description: 'Interaction Design',
      location: 'Mexico City, Mexico',
      yearStart: 2014,
      yearEnd: 2018,
    }, 
    
  ],
  skills: [
    'Fluency and Proficiency in English and Spanish.',
    'Full Stack Web Development (MERN) MongoDB, Express, React, Node, JavaScript, HTML, CSS.',
    'Interactive, UX, UI, Conceptual and Visual Design. \n Adobe Suite, Sketch, Zeplin, MS Office.',  
    '3D Modeling, Texturing, Illumination, Animation, Storyboarding, Motion graphics. \n (Maya, Z-Brush)',
  ],
};

const CVSkill = ({skill}) => {
  return (
    <p css={{marginBottom: '25px'}}>
      {skill.split('\n').map((line, index) => <React.Fragment key={`skill-line-${index}`}>{line}<br/></React.Fragment>)}
    </p>
  )
}

const CVEducation = ({education}) => {
  const {title, description, location, yearStart, yearEnd} = education;
  return (
    <div css={{marginBottom: '50px'}}>
      <H5>{title}</H5>
      <p>{description.split('\n').map((line, index) => <React.Fragment key={`education-line-${index}`}>{line}<br/></React.Fragment>)}</p>
      <p css={{fontSize: '14px', fontWeight: 'light', color: colors.gray54}}>{location}</p>
      <p css={{fontSize: '14px', fontWeight: 'light', color: colors.gray54}}>{yearStart} - {yearEnd}</p>
    </div>
  );
};

const CVExperience = ({experience = {}}) => {
  const {title, role, yearStart, yearEnd = 'present', description, activities} = experience;
  return (
    <div css={{
      marginBottom: '50px',
      [mq.large]: {
        display: 'flex',
        justifyContent: 'space-between',
      }
    }}>
      <div css={{
        [mq.large]: {
          width: '35%',
        }
      }}>
      <H4>{title.split('\n').map((line, index) => <React.Fragment key={`experience-line-${index}`}>{line}<br/></React.Fragment>)}</H4>
        
        <p css={{color: colors.gray87, marginBottom: '5px'}}>{role}</p>        
        <p css={{fontSize: '14px', fontWeight: 'light', color: colors.gray54}}>{yearStart} - {yearEnd}</p>
      </div>
      <div css={{marginTop: '20px',
        [mq.large]: {
          width: '62%',
          maxWidth: '640px',
          marginTop: '0',
        }
      }}>
        <p>{description}</p>
        <ul css={{
          marginTop: '15px',
          '> li':{
            listStyle: 'disc inside',
          }
        }}>
          {activities.map((activity, index) => <li key={`activity-${index}`}>{activity}</li>)}
        </ul>
      </div>
    </div>
  )
};

const CVSection = ({title, children}) => {
  return (
    <div css={{ marginTop: '70px', }}>
      <div css={{width: '100%', marginBottom: '30px', paddingBottom: '15px', borderBottom: `solid 0.5px ${colors.gainsboro}`}}>
        <p css={{fontSize: '24px', color: colors.gray30}}>{title}</p> 
      </div>
      {children}
    </div>
  )
};

const Curriculum = () => {
  return (
    <div>
      <div css={{
        width:'92%',
        margin: '0 auto',
        padding: '14px',
        borderRadius: '15px',
        backgroundColor: colors.cultured,
        textAlign: 'left',
        boxSizing: 'border-box',
        [mq.large]: {
          padding: '30px',
        }
      }}>
        <div css={{
          [mq.large]: {
            display: 'flex',
            justifyContent: 'space-between',
          }
        }}>
          <H3>Carlos Hernández Mancilla</H3>
          <div css={{
            [mq.large]: {
              textAlign: 'right',
            }
          }}>
            <p>Mexico City, Mexico</p>
            <p>chdezmancilla@gmail.com</p>
            <p>linkedin.com/in/carlos-hm</p>
            <p>+52 1 (55) 5252 3763</p>
            <p>carloshm.com</p>
          </div>
        </div>
        <CVSection title='Profesional Experience'>
          {experiences.profesional.map((experience, index) => <CVExperience key={`experience-${index}`} experience={experience}/>)}
        </CVSection>
        <CVSection title='Education'>
          <div css={{
            [mq.large]: {
              display: 'flex',
              '> div:first-of-type': {
                marginRight: '80px',
              }
            }
          }}>
            {experiences.education.map((education, index) => <CVEducation key={`education-${index}`} education={education}/>)}
          </div>
        </CVSection>
        <CVSection title='Skills'>
          {experiences.skills.map((skill, index) => <CVSkill key={`skill-${index}`} skill={skill}/>)}
        </CVSection>
      </div>
    </div>
  )
};

export default Curriculum;