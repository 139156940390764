/** @jsx jsx */
import {jsx} from '@emotion/core'
import React from 'react';
import * as colors from '../styles/colors';
import * as mq from '../styles/media-queries';
import { Button, H2, BgBlured } from './lib';
import { ContactModal } from './Contact';

export const HomeHero = () => {
  const [modalState, setModalState] = React.useState('closed')
  function handleModal () {
      const nextState = modalState === 'closed' ? 'open' : 'closed'
      setModalState(nextState)
    }

  return (
    <>

    <article css={{
      [mq.large] : {
        display:'flex',
        flexDirection: 'row-reverse',
        alignItems: 'center',
        justifyContent: 'space-around',
        padding: '110px 0 150px',
      }
    }}>
      <BgBlured 
        one={{ width: '70%', top: '-10%', left: '2%', widthLarge: '44%', topLarge: '-10%', leftLarge: '36%'}}
        two={{ width: '70%', top: '20%', left: '30%', widthLarge: '60%', topLarge: '20%', leftLarge: '55%'}}  
      />
      <div 
        css={{
          position: 'relative',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          padding: '150px 0',
          maxWidth: '400px',
          margin: '0 auto',
          [mq.large]: {
            width: '40%',
            padding: '8% 0 26%',
            margin: '0',
          }
        }}
      >
        <div css={{
          position: 'absolute',
          width: '44%',
          height:'34%',
          '> figure': {
            position: 'absolute',
            height: 'auto',
            '> img': {
              filter : 'drop-shadow(0px 10px 15px rgba(0,0,0,0.16))',
            }
          },
        }}>
          <figure css={{
            width: '35%',
            top: '-25%',
            left: '-53%'
          }}>
            <img src='/img/JS-Icon.png' alt='JavaScript icon'/>
          </figure>
          <figure css={{
            width: '39%',
            top: '-50%',
            left: '113%',
          }}>
            <img src='/img/React-Icon.png' alt='React icon'/>
          </figure>
          <figure css={{
            width: '29%',
            top:'116%',
            left:'-43%',
          }}>
            <img src='/img/XD-Icon.png' alt='XD icon'/>
          </figure>
          <figure css={{
            width: '33%',
            top:'125%',
            left:'100%',
          }}>
            <img src='/img/Node-Icon.png' alt='Node icon'/>
          </figure>
        </div>
        <figure css={{
          // width: '155px',
          width: '44%',
        }}>
          <img css={{
            borderRadius: '40px',
            boxShadow: '0px 30px 50px 0px rgba(0,0,0,0.16)'
            }} 
            src='https://res.cloudinary.com/carlos-hm/image/upload/v1657762437/carloshm/ovrjr4v2jqz2pdvbm6ol.png' alt='Carlos HM'/>
        </figure>
      </div>
      <div css={{
        textAlign: 'left',
        margin: '50px auto 0',
        [mq.medium]: {
          textAlign: 'center',
          maxWidth: '580px',
        },
        [mq.large]: {
          width: '50%',
          textAlign: 'left',
          margin: '0',
        }
      }}>
        <H2 css={{color: colors.title, fontWeight: '900'}}>Designing and developing a great digital world.</H2>
        <div css={{
          display: 'flex',
          flexDirection: 'column',
          'p': {
            margin: '5px 0'
          }
          }}
        >
          <p>
            Hi there,
            <strong> I'm Carlos Hernández Mancilla, </strong> 
            a 
            <strong> Full Stack Designer with 5 years of experience in the software industry. </strong> 
          </p>
          <p>
            I'm actively looking for new challenges and projects that allow me to create innovative and enjoyable platforms. 
          </p> 
          <p>
            Currently 
            <strong> I'm Head of Design </strong> 
            at SafeRoom Technologies Inc.
          </p>
        </div>
       
          <div css={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            margin: '40px 0 110px',
            [mq.medium]: {
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'flex-start',
              justifyContent: 'center',
              '> button:first-of-type': {
                marginRight: '30px',
              }
            },
            [mq.large]: {
              justifyContent: 'flex-start',
              marginBottom: '0',
            }
          }}>
            <Button onClick={handleModal} css={{marginBottom: '25px'}}>Contact Me</Button>
            <Button as="a" href="https://www.linkedin.com/in/carlos-hm" target="_blank" variant={'secondary'}>
              <figure>
                <img src='/icons/ic-linkedin.svg' alt='Linkedin'/>
              </figure>
              Linkedin
            </Button>
          </div>
      </div>
    </article> 
    <ContactModal modalState={modalState} handleModal={handleModal}/>
    </>
  )
}