/** @jsx jsx */
import { jsx } from "@emotion/core"
import * as colors from '../styles/colors'
import * as mq from '../styles/media-queries'
import { Link } from 'react-router-dom'

function NotFoundScreen() {
  return (
    <div 
      className='container'
      css={{
        height: '70vh',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
      }}
    >
      <div 
        css={{
          '& > p': {
            fontFamily: "'Satoshi', sans-serif",
            fontSize: '26px',
            fontWeight: '700',
            color: colors.gray87,
            marginBottom: '10px',
            [mq.medium]: {
              fontSize: '30px',
            },
            [mq.large]: {
              fontSize: '40px',
            },
          '& > a': {
              fontFamily: "'Satoshi', sans-serif",
              color: colors.blueJeans,
              transition: 'color ease .4s',
            ':hover': {
              color: colors.carolinaBlue,
            }
          }
        },
        }}
      >
        <p>Sorry, nothing to see here...</p>
        <p>But you can see great stuff in my <Link to="portfolio">portfolio.</Link></p>
      </div>
    </div>
  )
}

export {NotFoundScreen}