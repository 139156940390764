/** @jsx jsx */
import {jsx} from '@emotion/core'
import React from 'react';
import * as colors from '../styles/colors';
import * as mq from '../styles/media-queries';
import { Label, TitleParagraphs, Input, Textarea, Button, BgBlured, ButtonClose } from './lib';
import { send } from '@emailjs/browser'

const ContactCard = () => {
  return (
    <div css={{
      minWidth: '250px',
      maxWidth: '250px',
      width: '250px',
      margin: '40px 0',
      padding: '20px 20px 35px',
      borderRadius: '20px',
      background: colors.white,
      boxShadow: '0px 22px 50px rgba(0,0,0, 0.16)',
      boxSizing: 'border-box',
      [mq.medium]: {
        width: '30%',
      }
    }}>
      <div
        css={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          marginBottom: '30px',
        }}
      >
        <figure css={{
          width: '60px',
          marginBottom: '20px',
          '> img': {
            borderRadius: '50%',
          }
        }}>
          <img src='https://res.cloudinary.com/carlos-hm/image/upload/v1657762437/carloshm/ovrjr4v2jqz2pdvbm6ol.png' alt='Carlos HM'/>
        </figure>
        <p css={{
          fontSize: '18px', 
          fontWeight: 'Bold',
          color: colors.gray87,
        }}>Carlos <br/> Hernández Mancilla</p>
      </div>
      <div css={{
        textAlign: 'left',
        '> div:first-of-type': {
          marginBottom: '15px',
        },
        'p': {
          margin: '5px 0',
        }
      }}>
        <div>
          <Label>Email</Label>
          <p>hi@carloshm.com</p>
        </div>
        <div>
          <Label>Linkedin</Label>
          <a href='https://www.linkedin.com/in/carlos-hm' target='_blank' rel="noreferrer"
            css={{
              display: 'block', 
              color: colors.blueJeans, 
              textDecoration: 'none', 
              margin: '5px 0',
              transition: 'color ease .4s',
              [mq.hover]: {
                ':hover': {
                  color: colors.carolinaBlue,
                }
              }
            }}
          >linkedin.com/in/carlos-hm</a>
      </div>
      </div>
    </div>
  )
};

const ContactForm = () => { 
  const [formStatus, setFormStatus] = React.useState('idle');
  const [emailStatus, setEmailStatus] = React.useState('idle');
  const [data, setData] = React.useState({
    name: '',
    email: '',
    message : '',
  })

  function resetData() {
    setData({
      name: '',
      email: '',
      message : '',
    })
  }

  function handleSubmit(event) {
    event.preventDefault();
    if(emailStatus === 'warning') return

    for (let key in data) {
      if(data[key].length <= 0) return
    }
    setFormStatus('sending')
    send(
      process.env.REACT_APP_EMAIL_JS_SERVICE_ID,
      process.env.REACT_APP_EMAIL_JS_TEMPLATE_ID,
      {to_name: 'Carlos', from_name: data.name, from_email: data.email, message: data.message},
      process.env.REACT_APP_EMAIL_JS_PUBLIC_KEY,
    )
    .then((response) => {
      setFormStatus('sent')
      console.log('Success!', response.status, response.text);
    })
    .catch((err) => {
      setFormStatus('idle')
      console.log('Failed...', err)
    })
  }

  function handleChange(event) {
    const {id, value } = event.target;
    setData({...data, [id]: value})
  }

  function handleEmail(event) {
    if(!event.target.value) return setEmailStatus('idle')
    const validateEmail = (email) => {
      return String(email)
        .toLowerCase()
        .match(
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        );
    };

    const isEmail = validateEmail(event.target.value);
    if(!isEmail) return setEmailStatus('warning');
    setEmailStatus('idle')
  }

  return (
    <form onSubmit={handleSubmit} css={{
      position: 'relative',
      minWidth: '250px',
      width: '100%',
      padding: '20px 20px 35px',
      marginTop: '40px',
      background: colors.white,
      boxShadow: '0px 22px 50px rgba(0,0,0, 0.16)',
      borderRadius: '20px',
      boxSizing: 'border-box',
      [mq.medium]: {
        width: '50%',
        maxWidth: '400px',
      },
    }}>
      <div 
        css={{
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          '& > div': {
            opacity: `${formStatus === 'sending' ? '.5' : '1'}`,
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
            marginBottom: '15px'
          },
        }}
      >
        <div>
          <Label htmlFor='name'>Name</Label>
          <Input 
            id='name' 
            type='text'
            placeholder='Your name'
            value={data.name}
            onChange={handleChange}
            required
          />
        </div>
        <div>
          <Label htmlFor='email'>Email</Label>
          <Input 
            id='email' 
            type='email' 
            placeholder='Your email'
            variant = {emailStatus}
            value={data.email}
            onChange={handleChange}
            onBlur={handleEmail}
            required
          />
          <p css={{
            width: '100%',
            marginTop: '5px',
            fontSize: '14px',
            textAlign: 'center',
            color: colors.tartOrange, 
          }}>
            { emailStatus === 'warning' ? 'Please enter a valid email' : ''}
          </p>
        </div>
        <div>
          <Label htmlFor='message'>Message</Label>
          <Textarea 
            id='message' 
            placeholder='Your message' 
            css={{resize: 'none'}} 
            rows='5'
            value={data.message}
            onChange={handleChange}
            required
          />
        </div>
        <Button css={{width: '100%', margin: '30px 0 0px'}}>{formStatus === 'idle' ? 'Send message' : 'Sending...'}</Button> 
      </div>
      <div 
        css={{
          opacity: `${formStatus === 'sent' ? '1' : '0'}`,
          pointerEvents: `${formStatus === 'sent' ? 'all' : 'none'}`,
          width: '100%',
          height: '100%',
          position: 'absolute',
          top: '0',
          left: '0',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          backgroundColor: colors.white,
          borderRadius: '20px',
          transition: 'opacity ease .4s'
        }}
      >
        <figure
          css={{
            width: '36%',
            alignSelf: 'center',
          }}
        >
          <img 
            css={{
              marginBottom: '20px',
              filter: 'drop-shadow(0px 10px 30px rgb(88 184 252 / .5)) invert(39%) sepia(79%) saturate(1380%) hue-rotate(188deg) brightness(108%) contrast(97%)',
            }} 
            src='/icons/ic-check.svg' 
            alt='check'
            />
        </figure>
        <div 
          css={{width: '80%'}}
        >
          <p css={{
            marginBottom: '10px',
            fontFamily: 'satoshi, sans-serif',
            fontWeight: '600',
            fontSize: '22px',
            color: colors.gray87,
            }}
          >{`Thanks for reaching out ${data.name}!`}</p>
          <p
            css={{marginBottom: '30px'}}
          >You'll hear from me soon, looking forward in talking to you.</p>
          <Button 
            onClick={() => {
              resetData()
              setFormStatus('idle')
            }} 
            type="button" 
            variant="secondary"
            css={{margin: '0 auto'}}
          >
            Got it
          </Button>
        </div>

      </div>
    </form>
  )
};

const Contact = ({className}) => {
  return (
    <div
      className={className}
      css={{
        position: 'relative',
        paddingBottom: '80px',
      }}
    >
      <TitleParagraphs title='Contact me!' paragraphs={["I'm actively looking for new challenges and projects, let's create amazing products."]}/>
      <div css={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        maxWidth: '760px',
        margin: '0 auto',
        [mq.medium]: {
          marginTop: '40px',
          flexDirection: 'row',
          justifyContent: 'space-around',
          alignItems: 'flex-start',
        }
      }}>
        <ContactCard/>
        <ContactForm/>
      </div> 
      <BgBlured 
        one={{ width: '70%', top: '-10%', left: '2%', widthLarge: '50%' , topLarge: '-15%', leftLarge: '0%'}}
        two={{ width: '70%', top: '20%', left: '30%', widthLarge: '80%' , topLarge: '26%', leftLarge: '33%'}}  
      /> 
    </div>
  );
};

export const ContactModal = ({modalState, handleModal}) => {
  const modalVariables = {
    open: {
      opacity: '1',
      pointerEvents: 'all',
      overflow: 'auto',
    }
  };

  function handleKeyDown(e) {
    if(modalState === 'closed' || e.key !== 'Escape') return
    handleModal()
  }

  React.useEffect(() => {
    document.addEventListener('keydown', handleKeyDown )
    return () => {
      document.removeEventListener('keydown', handleKeyDown )
    }
  })

  return (
    <div
      css={{
        backgroundColor: colors.white,
        opacity: '0',
        pointerEvents: 'none',
        height: '100vh',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'fixed',
        top: '0',
        left: '0',
        width: '100%',
        zIndex: '5',
        transition: 'all ease .4s',
        [mq.blur]: {
          backgroundColor: 'transparent',
          backdropFilter: 'blur(50px)',
        },
        ...modalVariables[modalState],
      }}
    >
      <div css={{
        height: 'calc(100vh - 18vh)',
        position: 'relative',
        width: '90%',
        '& > button': {
          position: 'absolute',
          top: '-8%',
          right: '0%',
        }
      }}> 
        <ButtonClose onClick={handleModal} type='button'/>
        <Contact/>
      </div>
    </div>
  )
}

export default Contact;
