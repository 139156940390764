/** @jsx jsx */
import { jsx } from "@emotion/core";
// eslint-disable-next-line no-unused-vars
import React from "react";
import { useParams } from "react-router";
import {Container, Content} from '../components/lib';
import {ProjectHero, ProjectIntro, ProjectSection, ProjectLinks, ProjectsMore} from '../components/project';
import projectsData from '../data/projects-data.json';

function ProjectScreen(){
  const {projectSlug} = useParams();
  const project = projectsData.find(project => project.slug === projectSlug);
  const projectsMore = projectsData.filter(project => project.slug !== projectSlug);

  return (
    <>
      <Container>
        <Content>
          <ProjectHero project={project}/>
          <ProjectIntro project={project}/>
          {project.sections.map((section, i) => 
            <ProjectSection key={`section-${i}`} title={section.title} text={section.text} media={section.media}/>
          )}
          {
            project.links && 
              <ProjectLinks title={project.title} roles={project.roles} iconURL={project.iconURL} shadowColor={project.shadowColor} links={project.links}/>
          }
        </Content>
      </Container>
      <ProjectsMore projects={projectsMore}/>
    </>
  )
};

export {ProjectScreen}