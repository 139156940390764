/** @jsx jsx */
import {jsx} from '@emotion/core'
import './App.css';
import { Footer } from './components/lib';
import Header from './components/Header';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { ProjectScreen } from './screens/project';
import { HomeScreen } from './screens/home';
import { NotFoundScreen } from './screens/notFound';
import ScrollToTop from './components/ScrollToTop';

function AppRoutes() {
  return (
    <ScrollToTop ignorePaths={['/portfolio', '/curriculum']}>
      <Routes>
        <Route path='/' element={<HomeScreen/>}>
          <Route path='portfolio' element={<HomeScreen/>}/>
          <Route path='curriculum' element={<HomeScreen/>}/>
        </Route>
        <Route path='/project/:projectSlug' element={<ProjectScreen/>}/>
        <Route path='*' element={<NotFoundScreen/>}/>
      </Routes>
    </ScrollToTop>
  )
}

function App() {
  return (
    <Router>
      <div className="App">
        <main>
          <Header/>
          <AppRoutes/>
          <Footer className="container"/>
        </main>
      </div>
    </Router>
  );
}

export default App;
