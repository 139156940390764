import React from "react";
import { useLocation } from "react-router";

const ScrollToTop = ({children, ignorePaths = []}) => {
  const location = useLocation();

  React.useEffect(() => {
    if(ignorePaths.indexOf(location.pathname) >= 0) return 
    window.scrollTo(0, 0);
  }, [ignorePaths, location])

  return <>{children}</>
};

export default ScrollToTop;