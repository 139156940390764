/** @jsx jsx */
import {jsx} from '@emotion/core'
import React from 'react';
import * as colors from '../styles/colors';
import * as mq from '../styles/media-queries';
import { ButtonNav, LiNav, LogoName, NavLiButton } from './lib';
import { Link } from 'react-router-dom';
import { ContactModal } from './Contact';

function NavList({menuSate, handleMenu, handleModal}) {
  return (
    <nav css={{
      marginTop: '50px',
      [mq.large]: {
        display: 'none',
      }
    }}>
      <ul css={{
        opacity: menuSate === 'open' ? 1 : 0,
        display: 'flex',
        flexDirection: 'column',
        listStyle: 'none', 
        transform: menuSate === 'open' ? 'translateY(0)' : 'translateY(30px)',
        transition: 'all ease 0.6s',
        '> a': {
          textDecoration: 'none',
        }
      }}>
        <Link to='/portfolio' onClick={handleMenu}>
          <LiNav>Portfolio</LiNav>
        </Link>
        <Link to='/curriculum' onClick={handleMenu}>
          <LiNav>Curriculum</LiNav>
        </Link>
        <LiNav onClick={handleModal} >Contact me</LiNav>
      </ul>
    </nav>
  )
}

function debounce(fn, ms) {
  let timer;
  return () => {
    clearTimeout(timer)
    timer = setTimeout(() => {
      timer = null
      fn(this, arguments)
    }, ms)
  }
}

function Header() {
  const [menuState, setMenuState] = React.useState('closed')
  const [modalState, setModalState] = React.useState('closed')
  const [headerState, setHeaderState] = React.useState('active')

  if(menuState === 'open') {
    document.body.classList.add('no-scroll')
  } else if(menuState === 'closed') {
    document.body.classList.remove('no-scroll')
  }

  function handleMenu () {
    const nextState = menuState === 'closed' ? 'open' : 'closed'
    setMenuState(nextState)
  }

  function handleModal () {
    const nextState = modalState === 'closed' ? 'open' : 'closed'
    setModalState(nextState)
  }

  React.useEffect(() => {
    const debounceHandleResize = debounce(
      function handleResize() {
        if(menuState === 'closed' || window.innerWidth < 880) return
        setMenuState('closed')
      }, 400)

    window.addEventListener('resize', debounceHandleResize)
    return () => window.removeEventListener('resize', debounceHandleResize)
  }, [menuState])

  React.useEffect(() => {
    let lastScroll = 0;
    function handleScroll() {
      const {scrollY} = window;
      if(scrollY < 100 || scrollY < lastScroll - 20) setHeaderState('active');
      if(scrollY > 150 && scrollY > lastScroll + 10) setHeaderState('hidden');
      lastScroll = scrollY;
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);;

  return (
    <header css={{
      width: '100%',
      maxWidth: '75rem', 
      height: menuState === 'open' ? '100vh' : '78px',
      borderBottom: `solid 0.5px ${colors.gray20}`,
      overflowY: 'hidden',
      position: 'fixed',
      top: headerState === 'active' ? '0' : '-100px',
      background: colors.white,
      zIndex: 5,
      transition: 'height ease-out 0.6s, top ease .4s',
      [mq.large]: {
        height: 'auto'
      }
    }}>
      <div css={{
        width: '90%',
        margin: ' 0 auto',
        padding: '30px 20px 10px',
        display: 'flex',
        justifyContent: 'space-between',
      }}>
        <Link to="/">
          <h1>
            <LogoName/>
          </h1>
        </Link>
        <ul css={{
          display: 'none',
          [mq.large]: {
            display: 'flex',
            width: '320px',
            justifyContent: 'space-between',
            alignItems: 'flex-end',
          }
        }}>
          <Link to="/portfolio">
            <NavLiButton>Portfolio</NavLiButton>
          </Link>
          <Link to="/curriculum">
           <NavLiButton>Curriculum</NavLiButton>
          </Link>
          <NavLiButton variant='highlight' onClick={handleModal}>Contact me</NavLiButton>
        </ul>
        <ButtonNav css={{
          [mq.large]: {
            display: 'none'
          }
        }}
          onClick={handleMenu}>
            {menuState === 'open' ? 'CLOSE' : 'MENU'}
        </ButtonNav>
      </div>
      <NavList menuSate={menuState} handleMenu={handleMenu} handleModal={handleModal}/>
      <ContactModal modalState={modalState} handleModal={handleModal}/>
    </header>
  )
};

export default Header;
