/** @jsx jsx */
import {jsx} from '@emotion/core';
import React from 'react';
import {LiToggle} from './lib';
import {useLocation} from 'react-router-dom';

export const Toggle = ({titles = [], children, margin = 0}) => {
  // State management from url
  const paths = ['/portfolio', '/curriculum']
  const location = useLocation();
  const toggleActive = paths.indexOf(location.pathname) === -1 ? 0 : paths.indexOf(location.pathname);
  const toggleRef = React.useRef(null);
  const executeScroll = () => toggleRef.current.scrollIntoView({
    behavior: "smooth",
  });

  React.useEffect(() => executeScroll(), [location])

  // Toggle functionality
  const container = React.useRef();
  const [active, setActive] = React.useState(toggleActive);
  const [height, setHeight] = React.useState('auto');

  const observer = React.useRef(
    new ResizeObserver(entries => {
      const activeIndex = entries[0].target.dataset.active;
      handleHeight(activeIndex);
    })
  )

  function handleHeight(index) {
    const clientHeight = container?.current?.children?.[index]?.firstChild?.clientHeight
    setHeight(clientHeight);
  }
  
  function handleToggle (event) {
    const index = parseInt(event.target.dataset.index);
    setActive(parseInt(index));
    handleHeight(index);
  }


  React.useEffect(() => {
    setActive(toggleActive)
    handleHeight(toggleActive)
  }, [toggleActive]);

  React.useEffect(() => observer.current.observe(container.current), [])

  return (
    <div 
      ref={toggleRef}
      css={{marginBottom: '150px'}}
    >
      <div css={{
        width:'92%',
        margin: '0 auto',
        paddingTop: '150px'
        }}>
        <ul css={{
          display: 'flex',
          alignItems: 'flex-end',
          marginBottom: '30px',
        }}>
          {titles.map((title, i) => <LiToggle to={title.toLowerCase()} key={i} data-index={i} variant={i === active ? 'active': 'inactive'} onClick={handleToggle}>{title}</LiToggle>)}
        </ul>
      </div>

      <div css={{
        overflow: 'hidden',
        height: height + 'px',
        transition: 'height ease-out 0.4s',
      }}>
        <div
          data-active={active}
          ref={container}
          css={{
            display: 'flex',
            transform: `translateX(${active * -100}%)`,
            transition: 'transform ease-out 0.4s',
            '> div': {
              minWidth: '100%', 
            }
          }}>
          {children}
        </div>
      </div>
    </div>
  )
}