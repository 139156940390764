/** @jsx jsx */
import { jsx } from "@emotion/core"
// eslint-disable-next-line no-unused-vars
import React from "react"
import {TitleParagraphs} from '../components/lib'
import {HomeHero} from '../components/hero'
import {Toggle} from '../components/Toggle'
import {Projects} from '../components/project'
import Curriculum from '../components/Curriculum'
import Contact from '../components/Contact'
import projectsData from '../data/projects-data.json'

function HomeScreen() {
  return (
    <>
      <div className='container'>
        <HomeHero/> 
        <TitleParagraphs title='My profesional experience' paragraphs={['I have focused mainly in designing and developing applications from end-to-end, including: research, brainstorming, wireframes, conceptual design, UI / UX design and front & back-end development. I can participate in areas related with product design, digital productions and software development.']}/>
      </div>
      <Toggle titles={['Portfolio', 'Curriculum']} >
        <Projects projects={projectsData}/>
        <Curriculum/>
      </Toggle>
      <Contact className="container"/>
    </>
  )
};

export {HomeScreen}